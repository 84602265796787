.drawer {
  position: fixed;
  top: 0;
  left: -280px; /* Inicialmente fora da tela */
  width: 240px;
  /* height: 100%; */
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 9;
}

.drawer.open {
  left: -40px; /* Quando aberto, o drawer vem para a tela */
}

.drawer-menu {
  list-style: none;
  margin: 0;
}

.menu-item {
  color: black;
}
.drawer-menu li {
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 8;
}

.overlay.open {
  visibility: visible;
  opacity: 1;
}
