body {
  background-color: #f6f7f8;
  margin: 0;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f6f7f800;
}

*::-webkit-scrollbar-thumb {
  background-color: #9f9f9f;
  border-radius: 20px;
}
a {
  text-decoration: none;
}

@font-face {
  font-family: "Moderat-Regular";
  src: local("Moderat"),
    url("./fonts/Moderat/Moderat-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Moderat-Bold";
  src: local("Moderat"),
    url("./fonts/Moderat/Moderat-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Moderat-Light";
  src: local("Moderat"),
    url("./fonts/Moderat/Moderat-Light.otf") format("opentype");
  font-weight: lighter;
}

/* @font-face {
  font-family: "Moderat-Regular-Italic";
  src: local("Moderat"),
    url("./fonts/Moderat/Moderat-Regular-Italic.otf") format("opentype");
  font-weight: regular;
} */
